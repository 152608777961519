import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { Transition } from '@headlessui/react'
import MenuItemDropdown from './MenuItemDropdown'
import ContactFormModal from './ContactFormModal'
import Logo from './logo-svg'
import Button from './atoms/Button'

function Navbar({ headerTransparent = false }) {
  const companyname = "LV's Iron Works"
  const description = 'desc'
  const slogan = '1 Handcrafted Wrought Iron Gates & Fences Serving Sacramento'
  const subtagline = 'Subtagline'
  const phonenumber = '(916) 704-2510'
  const mainnavigationstyle = 'inline'

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [contactFormOpen, setContactFormOpen] = useState(false)

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.classList.add('menu-expanded')
    }

    return function cleanup() {
      document.body.classList.remove('menu-expanded')
    }
  }, [mobileMenuOpen])

  const MenuItem = ({ item, source, headerTransparent }) => {
    let itemId = item.id
    let title = item.name
    let slug = item.url

    if (item.submenu && item.submenu.length > 0) {
      return (
        <MenuItemDropdown
          item={item}
          label={title}
          type={1}
          source={source}
          headerTransparent={headerTransparent}
        />
      )
    }
    return (
      <Link
        to={`${slug && slug.length < 2 ? '/' : slug}`}
        className={`${
          source && source == 'mobilemenu'
            ? 'block py-4 px-8 font-semibold border-b border-brand-500 text-brand-100 hover:text-brand-300'
            : `px-2 py-6 border-b-4 border-transparent ${
                headerTransparent
                  ? 'text-white hover:border-brand-300 hover:text-brand-200'
                  : 'text-gray-100 hover:text-brand-200  hover:border-brand-100 '
              }`
        }  font-semibold text-base   transition-colors duration-150 ease-in button-active-bhvr`}
      >
        {item.name}
      </Link>
    )
  }
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQueryInNav {
          site {
            siteMetadata {
              title
              description
              companyInfo {
                phone
                email
                addressLine1
                addressLine2
                city
                state
                zip
                license
              }
            }
          }
          allContentfulNavigation(filter: { name: { eq: "Main Navigation" } }) {
            edges {
              node {
                id
                name
                menuItems {
                  id
                  name
                  title
                  url
                  openInNewWindow
                  submenu {
                    id
                    name
                    menuItems {
                      id
                      name
                      title
                      url
                      openInNewWindow
                      subMenuSection {
                        id
                        name
                        title
                        url
                        menuItems {
                          id
                          name
                          title
                          url
                          openInNewWindow
                        }
                      }
                    }
                  }
                }
                campaign
              }
            }
          }
        }
      `}
      render={(data) => {
        const items = data.allContentfulNavigation.edges[0].node.menuItems
        return (
          <>
            <header
              className={`bg-brand-500 ${
                headerTransparent ? 'header--transparent-main' : ''
              }`}
            >
              <div
                className={`bg-brand-800 text-brand-100 font-medium block relative z-20`}
              >
                <div className="font-display mx-auto py-3 text-xs md:text-sm md:space-x-10  max-w-7xl px-4 md:px-6">
                  <span className="block text-center md:text-left">
                    #1 Sacramento Iron Gate &amp; Fence Company
                  </span>
                </div>
              </div>
              <div
                className={`${
                  headerTransparent
                    ? 'bg-white md:bg-transparent bg-transparent'
                    : 'md:shadow-sm bg-brand-500'
                } relative z-20`}
              >
                <div className="flex justify-between items-center px-4 md:px-6 py-4 md:space-x-10 max-w-7xl mx-auto ">
                  <Link
                    to="/"
                    className="w-56 p-2 text-white block"
                    title={companyname}
                  >
                    <Logo fill="#fff" className="w-56" />
                  </Link>

                  {mainnavigationstyle == 'inline' ? (
                    <div className="items-center font-display md:space-x-8 hidden lg:flex">
                      {items.map((item, i) => {
                        return (
                          <MenuItem
                            key={item.id}
                            item={item}
                            type={item.type}
                            headerTransparent={headerTransparent}
                          />
                        )
                      })}
                    </div>
                  ) : null}

                  {mainnavigationstyle == 'stacked' ? (
                    <div className="hidden lg:block font-display text-center">
                      <span className="font-semibold block">{slogan}</span>
                      <span className="font-normal text-sm text-gray-800 block">
                        {subtagline}
                      </span>
                    </div>
                  ) : null}

                  <div className="hidden md:block font-display">
                    <div className="flex flex-row lg:flex-col xl:flex-row items-center">
                      <div className="pr-8 lg:pr-0 xl:pr-8 text-center">
                        <p className="mb-0 text-gray-700 leading-5 text-sm xl:text-base hidden">
                          Give Us A Call Today
                        </p>
                        <a
                          className={`block font-bold text-lg md:text-xl lg:text-lg xl:text-2xl tracking-tight  font-display ${
                            headerTransparent ? 'text-white' : 'text-white'
                          }`}
                          href={`tel:${phonenumber}`}
                        >
                          {phonenumber}
                        </a>
                      </div>
                      <div>
                        <Button
                          color="secondary"
                          onClick={() => setContactFormOpen(true)}
                          className=" whitespace-no-wrap block cursor-pointer transition duration-150 ease-in-out font-display"
                        >
                          Free Estimate
                        </Button>
                        <ContactFormModal
                          modalOpen={contactFormOpen}
                          onModalClose={setContactFormOpen}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={`-mr-2 -my-2 ${
                      mainnavigationstyle == 'stacked'
                        ? 'md:hidden'
                        : 'lg:hidden'
                    }`}
                  >
                    <button
                      type="button"
                      onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                      className="text-center p-2 rounded-lg text-brand-300 hover:bg-brand-700  focus:text-gray-300  focus::bg-brand-700  focus:outline-none  transition duration-150 ease-in"
                    >
                      {mobileMenuOpen ? (
                        <svg
                          className="h-6 w-6 mx-auto"
                          x-description="Heroicon name: x"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          className="h-6 w-6 mx-auto"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                      )}

                      <span
                        className="block uppercase font-semibold"
                        style={{ fontSize: '.5rem' }}
                      >
                        {mobileMenuOpen ? 'Close' : 'Menu'}
                      </span>
                    </button>
                  </div>
                </div>

                <Transition
                  show={mobileMenuOpen}
                  enter="duration-200 ease-out"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100 ease-in"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                  className={`absolute inset-x-0 transition transform origin-top-right z-50 ${
                    mainnavigationstyle == 'stacked' ? 'md:hidden' : 'lg:hidden'
                  }`}
                  style={{ top: '100%' }}
                >
                  <div className=" shadow-lg font-display">
                    <div className="shadow-xs bg-brand-600">
                      <div>
                        {items.map((item, i) => {
                          return (
                            <MenuItem
                              key={i}
                              item={item}
                              type={item.type}
                              source={'mobilemenu'}
                            />
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>

              {mainnavigationstyle == 'stacked' ? (
                <div className="relative bg-white text-white md:shadow-sm md:border-none z-10 hidden md:block font-display">
                  <div className="flex items-center px-4 md:px-6 md:space-x-10 container mx-auto ">
                    {items.map((item, i) => {
                      return <MenuItem key={i} item={item} type={item.type} />
                    })}
                  </div>
                </div>
              ) : null}
            </header>

            <div className="sticky top-0 bg-brand-500 text-brand-900  shadow-sm md:border-none z-10 md:hidden">
              <div className="flex  items-center justify-between px-4 md:px-6  py-3 container mx-auto ">
                <div>
                  <p className="mb-0 text-white font-display hidden sm:block">
                    Give Us A Call Today
                  </p>
                  <a
                    className="block font-semibold text-xl font-display text-brand-100 font-display"
                    href={`tel:${phonenumber}`}
                  >
                    {phonenumber}
                  </a>
                </div>
                <button
                  onClick={() => setContactFormOpen(true)}
                  className="button-active-bhvr block cursor-pointer transition duration-150 ease-in-out focus:outline-none font-semibold px-4 py-2 lg:py-3 lg:px-5 rounded-2xl text-brand-600  bg-white hover:bg-gray-50 font-display"
                >
                  Free Estimate
                </button>
              </div>
            </div>
          </>
        )
      }}
    />
  )
}

export default Navbar
